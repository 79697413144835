export const brand = {
  title: 'SummeRise',
  description: 'Phase 3 (Final) Exam',
  image_url: '/logo.png',
  navbar_logo_url: '/navbar_logo.png',
  rules: `SummeRise (സമ്മറൈസ്), അവധിക്കാല പദ്ധതിയുടെ, ഒന്നാം ഘട്ട പരീക്ഷയാണ് നിങ്ങൾ അറ്റൻഡ് ചെയ്യാൻ പോകുന്നത്. ഇത് ഇന്ത്യയിലും വിദേശത്തും സാധുതയുള്ളതാണ്.
  <h4>യോഗ്യത</h4>
  
  <p>താഴെ പറയുന്ന യോഗ്യതാ മാനദണ്ഡങ്ങൾ ഉള്ളവർക്ക് മാത്രമേ മത്സരത്തി പങ്കെടുക്കാവൂ :</p>
  <ul> 
  <li>നിങ്ങൾ ഒരു വ്യക്തിഗത പങ്കാളിയായിരിക്കണം. നിങ്ങൾ പത്താം ക്ലാസിലോ അതിൽ താഴെയോ പഠിക്കുന്ന വിദ്യാർത്ഥിയായിരിക്കണം.</li>
  
  <li>7 മുതൽ 10 വരെയുള്ള വിദ്യാർത്ഥികൾ സീനിയർ വിഭാഗത്തിലുമാണ് പങ്കെടുക്കേണ്ടത്.</li>
  </ul>
  <h4>മത്സരത്തിന്റെ വിശദമായ വിവരങ്ങൾ :</h4>
  
  <p>എങ്ങനെ മത്സരത്തിൽ പ്രവേശിക്കാം</p>
  <ul>
  <li>സമയപരിധിക്കുള്ളിൽ ( ഉച്ചകഴിഞ്ഞ് 2 മുതൽ രാത്രി 8 വരെ [IST] ) നേരത്തെ പറഞ്ഞ, യോഗ്യതയുള്ള ആർക്കും, കൃത്യം രണ്ട് മണിക്ക് പുറത്ത് വരുന്ന ലിങ്കിലൂടെ മത്സരത്തിൽ പങ്കെടുക്കാം.</li>
  
  <li>സീനിയർ വിഭാഗത്തിൽ 25 ചോദ്യങ്ങളുമായിരിക്കും ഉണ്ടാവുക. ഓരോ ചോദ്യത്തിനും 4 ഓപ്ഷൻസ് വീതം ഉണ്ടായിരിക്കും.</li>
  
  <li>പരീക്ഷ തുടങ്ങി ഒരു മണിക്കൂറിനുള്ളിൽ മുഴുവൻ ചോദ്യങ്ങൾക്കും ഉത്തരം നൽകേണ്ടതാണ്.</li>
  
  <li>ഏറ്റവും കൂടുതൽ ചോദ്യങ്ങൾ‌ക്കും ശരിയായ ഉത്തരം നൽ‌കുന്ന ഒന്നിലധികം പേർ ഉണ്ടെങ്കിൽ, random draw - ലൂടെ വിജയികളെ തിരഞ്ഞെടുക്കും.</li>
  
  <li>തിരഞ്ഞെടുത്ത 3 വിജയികൾക്ക് ആകർഷകമായ സമ്മാനങ്ങൾ ഉണ്ടായിരിക്കും.</li>
  
  <li>വിജയികളാവുന്നവർ വിദ്യാർത്ഥി എന്ന നിലയിലുള്ള അവരുടെ ഐഡന്റിറ്റി തെളിയിക്കേണ്ടതാണ്.</li>
  <li>എക്സാം എഴുതുന്നവർക്ക് SummeRise CERTIFICATE ഉണ്ട്. മറക്കരുത്.  ചോദ്യങ്ങളെല്ലാം തീർന്ന് Submit കൊടുത്താൽ പിന്നെ Download certificate എന്നതിൽ ക്ലിക്ക് ചെയ്താൽ മതി. നിങ്ങളുടെ ഫോണിലേക്ക് സർട്ടിഫിക്കറ്റ് ഡൗൺലോഡ് ആകും.</li>
  </ul>`,
  neutral_text: `<p>Top 3 winners will be announced later through the official
  <a href="https://facebook.com/wisdomstudents" rel="noopener noreferrer" target="_blank">Facebook</a> and
  <a href="https://instagram.com/wisdomstudents" rel="noopener noreferrer" target="_blank">Instagram</a> accounts of Wisdom Students.
</p><p>`,
  fail_text: 'Try later',
  win_text: `You won!`,
};
